<template>
  <b-container fluid>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <template v-slot:headerTitle> </template>
            <div class="p-2">
              <b-button
                @click="$router.push('/helex/Onboarding_admin')"
                variant="outline-danger"
                class="mx-1"
              >
                <i class="fas fa-chevron-left"></i>
                Regresar
              </b-button>
            </div>
            <h4 class="px-4 py-4"><b>NUEVO ONBOARDING</b></h4>
            <div class="row px-5 pb-5">
              <!-- usuario asignado -->
              <div class="col-xl-6">
                <div>
                  <label for=" " class="form-label"
                    ><em class="text-danger font-weight-bolder">Paso 1</em> -
                    Seleccionar Usuario</label
                  >
                  <!-- vue-select -->
                  <div
                    style="
                      background: transparent;
                      padding: 0rem;
                     
                    "
                  >
                    <v-select
                      class="style-chooser"
                      @input="getPersonaUbicacion(onboarding.personaId)"
                      :reduce="(value) => value.id"
                      :options="listaPersonas"
                      :get-option-label="
                        (option) => `${option.nombres} ${option.apellidos}`
                      "
                      v-model="onboarding.personaId"
                      placeholder="-- seleccionar persona --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!onboarding.personaId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>

                      <!--  agregar contenido adicional a las opciones-->
                      <template v-slot:option="option">
                        {{ `${option.nombres} ${option.apellidos}` }}
                      </template>

                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No tienes personas a cargo.</em
                        >
                      </template>
                    </v-select>
                  </div>
                  <!-- <b-form-select
                    required
                    @change.native="getPersonaUbicacion(onboarding.personaId)"
                    v-model="onboarding.personaId"
                  >
                    <template>
                      <b-form-select-option selected :value="null" disabled
                        >-- seleccionar usuario --</b-form-select-option
                      >
                    </template>
                    <b-form-select-option
                      :value="it.id"
                      v-for="it of listaPersonas"
                      :key="it.id"
                      >{{
                        it.nombres + " " + it.apellidos
                      }}</b-form-select-option
                    >
                  </b-form-select> -->
                </div>

                <!-- actividades por cargo -->
                <label for=" " class="form-label mt-3"
                  ><em class="text-danger font-weight-bolder">Paso 2</em> -
                  Seleccionar Actividades
                  <span v-show="listaActividades.length" class="text-primary"
                    >{{ onboarding.actividadesId.length }}/{{
                      listaActividades.length
                    }}</span
                  >
                </label>
                <b-form-select
                  required
                  v-model="onboarding.actividadesId"
                  :select-size="8"
                  multiple
                >
                  <template #first>
                    <b-form-select-option selected :value="null" disabled
                      >-- selecciona una opción --</b-form-select-option
                    >
                  </template>
                  <b-form-select-option
                    :value="it.id"
                    v-for="(it, index) of listaActividades"
                    :key="it.id"
                    >{{ index + 1 }}. {{ it.nombre }}</b-form-select-option
                  >
                </b-form-select>
                <b-button
                  v-show="personaUbicacion"
                  class="my-2"
                  variant="outline-success"
                  v-b-modal.my-modal
                  ><i class="fas fa-wrench"></i> Administrar
                  actividades</b-button
                >
              </div>

              <div class="col-xl-6">
                <!-- starDate -->
                <div>
                  <label for=" " class="form-label"
                    ><em class="text-danger font-weight-bolder">Paso 3</em> -
                    Seleccionar Fecha inicio</label
                  >
                  <input
                    required
                    v-model="onboarding.fechainicio"
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>

                <!-- endDate -->
                <div class="mt-3">
                  <label for=" " class="form-label"
                    ><em class="text-danger font-weight-bolder">Paso 4</em> -
                    Seleccionar Fecha fin</label
                  >
                  <input
                    required
                    v-model="onboarding.fechaFin"
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
                <!-- descripcion -->
                <div class="mt-3">
                  <label for=" " class="form-label"
                    ><em class="text-danger font-weight-bolder">Paso 5</em> -
                    Escriba una Descripción</label
                  >
                  <b-form-textarea
                    v-model="onboarding.descripcion"
                    placeholder="Escriba una descripción..."
                    minlength="10"
                    maxlength="255"
                    required
                  ></b-form-textarea>
                </div>
              </div>
            </div>

            <div class="text-right px-5 pb-4">
              <b-button
                type="submit"
                variant="outline-success"
                class="mx-1 px-4 py-2"
              >
                Guardar Onboarding
              </b-button>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>

    <!-- modal administrar actividades -->
    <b-modal
      title="Administrar Actividades"
      id="my-modal"
      body-bg-variant="dark"
      header-bg-variant="dark"
      footer-bg-variant="dark"
      size="lg"
      hide-footer
    >
      <!-- msg no existe actividades -->
      <p v-show="!listaActividades.length">
        No existen actividades para este cargo ...
        <a
          href="#"
          @click="() => (isCreatedActivity = !isCreatedActivity)"
          class="text-danger"
          >{{ !isCreatedActivity ? "Crear nueva" : "Cancelar" }}</a
        >
      </p>

      <!-- list actividad -->
      <a
        v-show="
          (listaActividades.length && !isCreatedActivity) ||
          (listaActividades.length && isCreatedActivity)
        "
        href="#"
        @click="() => (isCreatedActivity = !isCreatedActivity)"
        :class="`mb-2 btn ${
          !isCreatedActivity ? 'btn-outline-success' : 'btn-outline-danger'
        }`"
        ><i v-if="!isCreatedActivity" class="fas fa-plus"></i
        >{{ !isCreatedActivity ? "Crear nueva" : "Cancelar" }}</a
      >

      <div
        v-if="listaActividades.length && !isCreatedActivity"
        class="d-flex flex-column align-items-start"
      >
        <div class="mt-2 w-100">
          <h4 class="mb-2">Lista de actividades</h4>
          <template v-if="listaActividades.length">
            <div
              class="mb-2"
              v-for="(act, index) of listaActividades"
              :key="act.id"
            >
              <b-button
                block
                class="d-flex justify-content-between align-items-center"
                v-b-toggle="`collapse-${index}`"
                variant="primary"
                ><span class="text-truncate"
                  >{{ index + 1 }}. {{ act.nombre }}</span
                >&nbsp; <i class="fas fa-chevron-down"></i
              ></b-button>
              <b-collapse :id="`collapse-${index}`" class="mt-2">
                <b-card class="position-relative">
                  <small
                    class="position-absolute text-danger pointer"
                    @click="deleteActividad(act.id)"
                    style="right: 5px; font-size: 15px; top: 5px"
                    ><i class="fas fa-trash text-light"></i>
                  </small>
                  <h4 class="mb-2">Información de actividad</h4>
                  <p class="card-text font-italic">
                    <b>Nombre: </b>{{ act.nombre }}
                  </p>
                  <p class="card-text font-italic">
                    <b>Descripcion: </b>{{ act.descripcion }}
                  </p>
                  <!-- actions for actividades -->
                  <div class="d-flex flex-column" style="gap: 20px">
                    <!-- editar actividad -->
                    <b-button v-b-toggle="`collapse-${index}-inner`"
                      >Editar actividad <i class="fas fa-pen"></i
                    ></b-button>
                    <b-collapse :id="`collapse-${index}-inner`" class="mt-2">
                      <b-card>
                        <form @submit.prevent="sendUpdateActividad(act)">
                          <div
                            class="d-flex flex-column justify-content-between"
                          >
                            <!-- nombre -->
                            <div class="w-100">
                              <label for=" " class="form-label">Nombre</label>
                              <input
                                required
                                v-model="act.updateAct.nuevoNombre"
                                @input="act.changeValue = true"
                                placeholder="Escriba el nombre de la actividad..."
                                type="text"
                                class="form-control"
                                id=" "
                              />
                            </div>
                            <!-- descripcion -->
                            <div class="w-100 mt-2">
                              <label for=" " class="form-label"
                                >Descripción</label
                              >
                              <b-form-textarea
                                v-model="act.updateAct.nuevaDescripcion"
                                @input="act.changeValue = true"
                                placeholder="Escriba una descripción..."
                                minlength="10"
                                maxlength="255"
                                required
                              ></b-form-textarea>
                            </div>
                            <div class="text-right mt-3">
                              <b-button
                                block
                                type="submit"
                                variant="outline-info"
                                :disabled="!act.changeValue"
                              >
                                <i class="fas fa-paper-plane"></i> Guardar
                                cambios
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </b-card>
                    </b-collapse>
                  </div>
                </b-card>
              </b-collapse>
            </div>
          </template>
          <p v-else>cargando actividades...</p>
        </div>
      </div>

      <!-- create actividad -->
      <form v-show="isCreatedActivity" @submit.prevent="createActividad">
        <b-row>
          <b-col sm="12">
            <template v-slot:headerTitle> </template>
            <h4 class="px-4 py-4"><b>CREAR ACTIVIDAD</b></h4>
            <div class="row px-5 pb-5">
              <!-- nombre -->
              <div class="col-xl-6 mb-2">
                <label for=" " class="form-label">Nombre</label>
                <input
                  required
                  v-model="newActividad.nombre"
                  placeholder="Escriba el nombre de la actividad..."
                  type="text"
                  class="form-control"
                  id=" "
                />
              </div>
              <!-- descripcion -->
              <div class="col-xl-6 mb-2">
                <label for=" " class="form-label">Descripción</label>
                <b-form-textarea
                  v-model="newActividad.descripcion"
                  placeholder="Escriba una descripción..."
                  minlength="10"
                  maxlength="255"
                  required
                ></b-form-textarea>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="text-right px-5 pb-4">
          <b-button type="submit" variant="outline-info" class="mx-1 px-4 py-2">
            Guardar Actividad
          </b-button>
        </div>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";

Vue.component("v-select", vSelect);

const Swal = require("sweetalert2");
export default {
  name: "Onboarding_admin",
  data() {
    return {
      persona: null,
      listaPersonas: [],
      personaUbicacion: null,
      listaActividades: [],
      isCreatedActivity: null,

      newActividad: {
        nombre: null,
        descripcion: null,
        cargoId: null,
        porcentajeCierre: null,
        estado: null,
        usuarioAsignadoId: null,
        usuarioResponsableId: null,
        empresaId: null,
      },
      updateActividad: {
        actividadOnboardingId: null,
        nuevoNombre: null,
        nuevaDescripcion: null,
      },
      newActividadProgreso: {
        onboardingAdminId: null,
        actividadOnboardingId: null,
        porcentajeAut: 0,
        comentarioAut: "",
        porcentajeResp: 0,
        comentarioResp: "",
        fechaCreacion: null,
        fechaCierre: null,
      },
      onboarding: {
        personaId: null,
        responsableId: null,
        actividadesId: [],
        descripcion: null,
        fechainicio: null,
        fechaFin: null,
        estado: 0,
      },
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.getPersonas();
    this.$isLoading(false);
  },
  methods: {
    async saveData() {
      try {
        let tempActividadesId = this.onboarding.actividadesId;
        console.log(this.onboarding);
        let res = await this.$store.dispatch("hl_post", {
          path: "OnboardingAdmin/",
          data: {
            ...this.onboarding,
            actividadesId: this.onboarding.actividadesId.join(),
            responsableId: this.persona.id,
          },
        });
        console.log(res);
        if (res) {
          for (let actividadId of tempActividadesId) {
            try {
              const datos = {
                ...this.newActividadProgreso,
                onboardingAdminId: res.id,
                actividadOnboardingId: actividadId,
                fechaCreacion: this.$moment
                  .tz(new Date(), "America/Bogota")
                  .format("YYYY-MM-DDTHH:mm"),
                fechaCierre: this.$moment
                  .tz(new Date(), "America/Bogota")
                  .format("YYYY-MM-DDTHH:mm"),
              };
              await this.createActividadProgreso(datos);
            } catch (error) {
              console.error(
                "Error al crear ActividadProgreso para actividadId:",
                actividadId,
                error
              );
            }
          }
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              this.initFormOnboarding();
              this.$router.push("/helex/Onboarding_admin");
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async sendUpdateActividad(act) {
      console.log(act);
      try {
        let data = act.updateAct;
        let res = await this.$store.dispatch("hl_post", {
          path: "ActividadOnboarding/UpdateActividadNombreDescripcion/",
          data,
        });
        console.log(res);
        if (res) {
          Swal.fire("Listo!", "Registro actualizado correctamente.", "success");
          act.nombre = act.updateAct.nuevoNombre;
          act.descripcion = act.updateAct.nuevaDescripcion;
          act.changeValue = false;
        } else {
          Swal.fire("Ops!", "No fue posible actualizar el registro.", "error");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createActividadProgreso(newActividadProgreso) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "ActividadOnboarding/CreateActividadProgreso/",
          data: newActividadProgreso,
        });
        console.log(res);
        return res; //true o false
      } catch (error) {
        console.log("err", error);
      }
    },
    async createActividad() {
      try {
        let data = {
          ...this.newActividad,
          cargoId: this.personaUbicacion.cargo.id,
          porcentajeCierre: 100,
          estado: 1,
          usuarioAsignadoId: this.onboarding.personaId,
          usuarioResponsableId: this.persona.id,
          empresaId: this.persona.empresa.id,
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "ActividadOnboarding/CreateActividad/",
          data,
        });
        console.log(res);
        if (res) {
          Swal.fire({
            title: "Listo!",
            text: "Registro almacenado correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              this.initFormCreateActividad();
              this.getActividadesByCargoId(this.personaUbicacion.cargo.id);
              this.isCreatedActivity = false;
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    initFormOnboarding() {
      for (let prop in this.onboarding) {
        this.onboarding[prop] = null;
      }
    },
    initFormCreateActividad() {
      this.newActividad.nombre = null;
      this.newActividad.descripcion = null;
    },
    async getPersonas() {
      try {
        console.log(
          "get personas/////////////////////////////////////////////..."
        );
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.persona.rol.nivel < 3
              ? "Persona/ListPersonaJefe/" + this.persona.id
              : "Persona/ListPersona/" + this.persona.empresa.id
          }`,
        });
        console.log("...", res);
        if (res.length > 0) {
          this.listaPersonas = res;
        } else {
          this.listaPersonas = [];
        }
      } catch (error) {
        this.listaPersonas = [];
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      // limpiar valores
      if (this.listaActividades.length) {
        this.listaActividades = [];
      }
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        console.log("personaUbicacion...", res);
        this.personaUbicacion = res;
        this.getActividadesByCargoId(res.cargo.id);
      } catch (error) {
        this.personaUbicacion = null;
        console.log("err", error);
      }
    },
    async getActividadesByCargoId(cargoId) {
      console.log(cargoId);
      this.isCreatedActivity = false;
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "ActividadOnboarding/GetActividadByCargoId/" + cargoId,
        });
        console.log("actividadesByCargoId...", res);
        if (res.length > 0) {
          this.listaActividades = res.map((act) => ({
            ...act,
            changeValue: false,
            updateAct: {
              actividadOnboardingId: act.id,
              nuevoNombre: act.nombre,
              nuevaDescripcion: act.descripcion,
            },
          }));
        } else {
          this.listaActividades = [];
        }
      } catch (error) {
        this.listaActividades = [];
        console.log("err", error);
      }
    },
    async deleteActividad(actividadId) {
      console.log("-------------->delete", actividadId);
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.$store.dispatch("hl_get", {
            path: "ActividadOnboarding/Delete/" + actividadId,
          });
          if (res) {
            this.getActividadesByCargoId(this.personaUbicacion.cargo.id);
            Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
          } else {
            Swal.fire(
              "Actividad en procesos!",
              "La actividad se encuentra asignada en uno o varios Onboardings, NO es posible eliminarla.",
              "warning"
            );
          }
        }
      });
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
</style>
